import React, { useMemo, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { CSVLink } from "react-csv";
import capitalizeFirstLetter from "../../../helpers/capitalizeFirstLetter";
import detox from "../../../assets/detox.pdf";
import sleep from "../../../assets/sleep.pdf";
import stress from "../../../assets/stress.pdf";
import elimination from "../../../assets/elimination.pdf";
import { sendEmail } from "../../../helpers/sendEmail";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "50px 0",
  },
  question: {
    marginTop: "25px",
    padding: "30px",
    maxWidth: "80%",
    textAlign: "center",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "40px",
    "& > *": {
      margin: theme.spacing(2),
    },
    // width: '500px'
  },
}));

const Result = ({ result, testType, setShowShopModal, userData }) => {
  const classes = useStyles();
  const title =
    testType === "darm" ? (result?.title === "Stressmanagement" ? "stress" : "elimination") : testType;
  const getPdf = (testType) => {
    if (testType === "darm") {
      if (result?.title === "Stressmanagement") return stress;
      else return elimination;
    }
    switch (testType) {
      case "detox":
        return detox;
      case "sleep":
        return sleep;
      case "stress":
        return stress;
      default:
        return detox;
    }
  };
  const pdf = useMemo(() => getPdf(testType), [testType]);

  useEffect(() => {
    sendEmail(testType, userData, result);
  }, []);

  return (
    <div className={classes.root}>
      <Typography color="textSecondary" variant="h5">
        {capitalizeFirstLetter(testType)} Test: Resultat
      </Typography>
      <Typography color="textPrimary" variant="h4" style={{ margin: "5px 0 35px 0" }}>
        {result?.title}
      </Typography>
      {result?.text && result?.text !== "-" && (
        <Typography
          color="textPrimary"
          variant="h6"
          style={{
            marginBottom: "35px",
            padding: "30px",
            maxWidth: "80%",
            textAlign: "center",
            background: "#ECE4C8",
          }}
        >
          {result?.text}
        </Typography>
      )}
      <Typography color="textPrimary" variant="h6">
        Empfohlene Produkte zur Unterstützung:
      </Typography>
      <Typography color="textPrimary" variant="h5" style={{ margin: "5px 0 20px 0" }}>
        {result?.products}
      </Typography>
      <div className={classes.buttonsWrapper}>
        {result?.title !== "All good" && (
          <Button size="large" variant="outlined">
            <a href={pdf} download={`${title}.pdf`} style={{ color: "inherit", textDecoration: "none" }}>
              Download Ebook
            </a>
          </Button>
        )}
        <Button onClick={() => setShowShopModal(true)} variant="contained" color="secondary">
          zum den Shop Artikeln
        </Button>
      </div>
    </div>
  );
};

export default Result;
