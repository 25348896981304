import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#272343'
        },
        secondary: {
            main: '#A71580'
        },
        text: {
            primary: '#272343',
            secondary: 'rgba(39, 35, 67, 0.65)'
        }
    },
})

export default theme