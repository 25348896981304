import React from 'react'
import logo from '../../../assets/logo.png'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '70px',
        padding: '8px',
        backgroundColor: '#000'
    },
    logo: {
        height: '100%'
    }
}));

const Header = () => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <img alt="" className={classes.logo} src={logo} />
        </div>
    )
}

export default Header