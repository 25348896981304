import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { CSVLink } from "react-csv";
import capitalizeFirstLetter from "../../../helpers/capitalizeFirstLetter";
import stress from "../../../assets/stress.pdf";
import stress2 from "../../../assets/stress2.pdf";
import { sendEmail } from "../../../helpers/sendEmail";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "50px 0",
  },
  question: {
    marginTop: "25px",
    padding: "30px",
    maxWidth: "80%",
    textAlign: "center",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "40px",
    "& > *": {
      margin: theme.spacing(2),
    },
    // width: '500px'
  },
  resultItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "40px 0",
  },
  additionalInfo: {
    margin: "40px 0 0 0",
  },
  grouped: {
    margin: "0 0 40px 0",
  },
}));

const Result = ({ result, testType, setShowShopModal, userData }) => {
  const classes = useStyles();

  useEffect(() => {
    sendEmail('hormone', userData, result)
  }, [])

  return (
    <div className={classes.root}>
      <Typography color="textSecondary" variant="h5">
        Hormone Test: Resultat
      </Typography>
      {result.map((resultItem) => (
        <div
          key={resultItem?.title ?? Math.random().toString()}
          className={`${classes.resultItem} ${resultItem?.title ? "" : classes.additionalInfo} ${
            resultItem?.grouped ? classes.grouped : ""
          }`}
        >
          <Typography color="textPrimary" variant="h4" style={{ marginBottom: "20px" }}>
            {resultItem?.title}
          </Typography>
          <Typography
            color="textPrimary"
            variant="h6"
            style={{
              padding: "30px",
              maxWidth: "80%",
              textAlign: "center",
              background: "#ECE4C8",
            }}
          >
            {resultItem?.text}
          </Typography>
          {resultItem?.products && (
            <Typography color="textPrimary" variant="h6" style={{ marginTop: "20px" }}>
              Empfohlene Produkte zur Unterstützung:
            </Typography>
          )}
          <Typography color="textPrimary" variant="h5" style={{ margin: "5px 0 0 0" }}>
            {resultItem?.products}
          </Typography>
        </div>
      ))}
      <div className={classes.buttonsWrapper}>
        <Button size="large" variant="outlined">
          <a href={stress} download="Stress.pdf" style={{ color: "inherit", textDecoration: "none" }}>
            Download Ebook Stress
          </a>
        </Button>
        <Button size="large" variant="outlined">
          <a href={stress2} download="Östrogenhandbuch.pdf" style={{ color: "inherit", textDecoration: "none" }}>
          Download Östrogenhandbuch
          </a>
        </Button>
        <Button onClick={() => setShowShopModal(true)} variant="contained" color="secondary">
          zum den Shop Artikeln
        </Button>
      </div>
    </div>
  );
};

export default Result;
