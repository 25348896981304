const getMenResult = (age, answers) => {
  const groups = Object.keys(answers);
  let appliedGroups = groups.filter((group) => {
    if (age > 55 && group === "E") {
      return answers[group].length > 1;
    } else {
      return answers[group].length > 2;
    }
  });
  const additionalInfo = [];

  if (age > 55 && appliedGroups.includes("D")) {
    appliedGroups = appliedGroups.filter((group) => group !== "D");
  }

  if (appliedGroups.includes("B") && appliedGroups.includes("C")) {
    appliedGroups = appliedGroups.filter((group) => group !== "B");
  }

  if (appliedGroups.includes("F") && appliedGroups.includes("G")) {
    additionalInfo.push({
      parent: "F",
      text: `Cortisolmangel und Cortisolerhöhung schließen sich nicht aus. Die Entwicklung
        zum Burnout ist fließend und erfolgt in drei Stufen: die Alarmreaktion, das
        Widerstandsstadium und der Burnout. Die Symptome können sich zwischen den
        Stufen überlappen, weshalb häufig Ergebnisse aus beiden Gruppen vorkommen.`,
    });
  }

  if (age <= 55) {
    if (appliedGroups.includes("D") && appliedGroups.includes("E")) {
      if (age > 45) {
        appliedGroups = appliedGroups.filter((group) => group !== "E");
      } else {
        if (answers["E"].length >= answers["D"].length) {
          appliedGroups = appliedGroups.filter((group) => group !== "D");
        } else {
          appliedGroups = appliedGroups.filter((group) => group !== "E");
        }
      }
    }
  }

  if (additionalInfo.length !== 0) {
    let parentIndex;
    additionalInfo.forEach((item) => {
      parentIndex = appliedGroups.indexOf(item.parent);
      appliedGroups.splice(parentIndex, 0, item);
    });
  }

  return [
    ...appliedGroups.map((item) => {
      if (typeof item === "object") {
        return {
          text: item.text,
        };
      }

      switch (item) {
        case "A":
          return {
            title: "Progesteronmangel",
            products: "Östro Control, Magnesium, Vitamin D3/K2, Multi, Omega 3",
            text: `Auch beim Mann wird Progesteron in geringen Mengen in den Nebennieren gebildet. Progesteron ist das Vorläuferhormon für sämtliche weitere Hormone, wie z.B.
                    Estrogen, Cortisol, Aldosteron und Testosteron. Ein Mangel kann zu unterschiedlichsten Beschwerdebilder führen. Die Progesteron-Konzetration beim Mann liegt in etwa
                    auf dem Level der ersten Zyklushälfte der Frau. Ein Progesteronmangel ist häufig aus Stress zurückzuführen, weshalb es empfehlenswert ist ein gutes Stressmanagement
                    durchzuführen.`,
          };
        case "B":
          return {
            title: "Östrogenmangel",
            products: "Östro Control, Magnesium, Vitamin D3/K2, Multi, Omega 3",
            text: `Östrogene sind weibliche Sexualhormone, die auch in geringen Mengen beim Mann in der Nebenniere, im Fettgewebe und in den Hoden gebildet werden. In der
                    Andropause sind die Östrogenspiegel häufig neben Testosteron ebenfalls niedrig und können sich auf die Knochengesundheit ungünstig auswirken. Pflanzliche Östrogen
                    wie in Schafgabentee oder Traubensilberkerze können hier unterstützend eingesetzt werden.`,
          };
        case "C":
          return {
            title: "Östrogenerhöhung",
            products: "Östro Control, Magnesium, Vitamin D3/K2, Multi, Omega 3",
            text: `Die Hauptproduktionsquelle von Östrogenen beim Mann ist das Fettgewebe. Auch Umweltgifte, wie Xenoestrogene, die beispielsweise über Luft und Wasser aufgenommen werden müssen beachtet werden. Estrogene können teilweise sehr langsam abgebaut werden und können somit zu einer Östrogenerhöhung bzw. Östrogendominanz führen. Die Verabreichung von Hormonen, wie (Östrogene), DHEA, Androstendion oder auch Testosteron kann zu einer Umwandlung in Östrogene bzw. zu einer hormonelle Dysbalance führen. Eine Östrogen-Erhöhung führt beim Mann zu weiblichen Veränderungen. In diesem Fall sollte darauf geachtet werden, Umweltgifte, wie Lebensmittel aus konventionellen Anbau insbes. konventionelles Fleisch, Trinkwasser aus Plastikflaschen und Leitungswasser zu vermeiden. Auch auf Naturkosmetika sollte umgestiegen werden, um die Aufnahme synthetischer Östrogene zu meiden. Auch Nähr- und Pflanzenstoffe sowie ein gut funktionierendes Verdauungssystem (Hast du schon deinen Darmtest gemacht?) können beim Ausscheiden der Östrogene behilflich sein.`,
          };
        case "D":
          return {
            title: "Testosteronerhöhung",
            products: "Östro Control, Magnesium, Vitamin D3/K2, Multi, Omega 3",
            text: `Erhöhte Testosteron-Werte können zu Akne, erhöhte Reizbarkeit und Aggression, Haarausfall und fettiger Haut führen. Natürliche Testosteronwerte bei Männern sind wichtig
                    für den Muskelaufbau und den Fettstoffwechsel, es steigert das sexuelle Verlangen und stärkt die psychische Verfassung.
                    Zur Unterstützung können jedoch auch Nähr- und Pflanzenstoffe eingesetzte werden. Studien zeigen, dass ein täglicher Konsum von 30g
                    Leinsamen täglich Testosteron sinken lässt.`,
          };
        case "E":
          return {
            title: "Testosteronmangel",
            products: "Östro Control, Magnesium, Vitamin D3/K2, Multi, Omega 3",
            text: `Die Testosteronwerte sinken beim Mann langsam aber kontinuierlich bis zur Andropause. Ein Mangel an Testosteron kann zu Muskel- und Knochenabbau, Libidoverlust,
                    Müdigkeit, Leistungsschwäche und Fettaufbau führen. Ein Mangel kommt häufig auch durch zu viele Stress vor, weshalb ein gutes Stressmanagement unabdingbar ist.
                    Auch gewisse Kräuter und Nährstoffe können in diesem Fall unterstützend wirken.`,
          };
        case "F":
          return {
            title: "Cortisol Erhöhung",
            products: "Magnesium, Vitamin D3/K2, Multi, Omega 3, Adaptobalance",
            text: `Mit der Cortisolausschüttung reagiert unser Körper auf Stress. In den meisten Fällen gehen die Symptome einher mit Symptomen eines Cortisolmangels, weil nach einer
                    Erhöhung meist der Mangel folgt. Zu hohe Cortisolwerte aufgrund von häufig wiederkehrenden Stressoren können zu zahlreichen negativen Auswirkungen, wie Burn-Out,
                    Schilddrüsenunterfunktion, Insulinresistenz, Diabetes 2, erhöhtes Bauchfett und kognitive Beeinträchtigungen uvm. führen. Vor allem Frauen sind einer Vielzahl an
                    Stressoren, wie z.B. Kinder, Familie, Arbeit, dem Ehefrau- und Mutter Dasein, Sport, etc. ausgesetzt. Neben einem guten Stressmanagement können Kräuter und
                    Nährstoffe unterstützend eingesetzt werden.`,
          };
        case "G":
          return {
            title: "Cortisol Mangel",
            products: "Magnesium, Vitamin D3/K2, Multi, Omega 3, Adaptobalance",
            text: `Ein Cortisol-Mangel ist häufig Resultat einer längerfristigen Aussetzung von Stressoren. In den meisten Fällen gehen die Symptome einher mit Symptomen eines
                    Cortisolmangels , weil nach einer Erhöhung meist der Mangel folgt. Das typische Syndrom ist der Burn-Out, bei dem die Nebenniere nicht mehr in der Lage ist Cortisol zu
                    produzieren. Erschöpfung, Ermüdung und eine geringe Belastbarkeit sind charakteristisch. Die natürliche Tageskurve des Cortisol ist nicht mehr gegeben. Neben einem
                    guten Stressmanagement können Kräuter und Nährstoffe unterstützend eingesetzt werden.`,
            grouped: true,
          };
        default:
          return {};
      }
    }),
  ];
};

const getWomenResult = (age, answers) => {
  const groups = Object.keys(answers);
  let appliedGroups = groups.filter((group) => {
    if (group === "A" && age > 40) {
      return answers[group].length > 1;
    }
    return answers[group].length > 2;
  });
  const additionalInfo = [];
  const excludeArr = [];

  if (appliedGroups.includes("B") && appliedGroups.includes("C")) {
    if (age > 45) {
      // appliedGroups = appliedGroups.filter((item) => item !== "C");
      // conflictGroups.push("B");
      excludeArr.push("C");
    } else {
      // appliedGroups = appliedGroups.filter((item) => item !== "B");
      // conflictGroups.push("C");
      excludeArr.push("B");
    }
  }

  if (appliedGroups.includes("G") && appliedGroups.includes("H")) {
    additionalInfo.push({
      parent: "G",
      text: "Cortisolmangel und Cortisolerhöhung schließen sich nicht aus. Die Entwicklung zum Burnout ist fließend und erfolgt in drei Stufen: die Alarmreaktion, das Widerstandsstadium und der Burnout. Die Symptome können sich zwischen den Stufen überlappen, weshalb häufig Ergebnisse aus beiden Gruppen vorkommen.",
    });
  }

  if (age <= 40) {
    if (appliedGroups.includes("A") && appliedGroups.includes("C")) {
      // appliedGroups = appliedGroups.filter((group) => group !== "A" && group !== "C");
      excludeArr.push("A", "C");
      if (!appliedGroups.includes("D")) {
        appliedGroups.push("D");
      }
    }
  }

  if (appliedGroups.includes("E") && appliedGroups.includes("F")) {
    if (answers["F"].length >= answers["E"].length) {
      // appliedGroups = appliedGroups.filter((item) => item !== "E");
      excludeArr.push("E");
    } else {
      // appliedGroups = appliedGroups.filter((item) => item !== "F");
      excludeArr.push("F");
    }
  }

  if (additionalInfo.length !== 0) {
    let parentIndex;
    additionalInfo.forEach((item) => {
      parentIndex = appliedGroups.indexOf(item.parent);
      appliedGroups.splice(parentIndex, 0, item);
    });
  }

  // [...conflictGroups, ...appliedGroups].forEach((item) => { //
  //   finalSet.add(item);
  // });

  // console.log("Result without the filters\n", appliedGroups);
  // console.log("What to exclude\n", excludeArr);

  appliedGroups = appliedGroups.filter((item) => !excludeArr.includes(item));

  // console.log("Result after excluding\n", appliedGroups);

  return [
    ...[...appliedGroups].map((item) => {
      if (typeof item === "object") {
        return {
          text: item.text,
        };
      }

      switch (item) {
        case "A":
          return {
            title: "Progesteron Mangel",
            products: "Östro Control, Magnesium, Vitamin D3/K2, Multi, Omega 3",
            text: `Progesteron sinkt natürlicherweise bei Frauen ab dem 35 Lebensjahr, ein Progesteron-Mangel kann häufig auch durch Stress hervorgerufen
                    werden. Deshalb solltest du immer einen Fokus auf Stressmanagement legen. Unsere Anleitung für dein perfektes Stressmanagement findest
                    du als kostenlosen Download. Zudem können bestimmte Kräuter dein Stresamanagement unterstützen und dein Progesteron stimulieren.`,
          };
        case "B":
          return {
            title: "Östrogenmangel",
            products: "Östro Control, Magnesium, Vitamin D3/K2, Multi, Omega 3",
            text: `Ein Östrogenmangel kommt relativ selten vor. Auch in der Menopause sinkt der Östrogenspiegel im Vergleich zum Progesteron nicht gegen
                    Null,, da auch in den Fettzellen Östrogene produziert werden. Sollte dein Körperfettanteil sehr gering sein, kannst du deinen Östrogenspiegel
                    jedoch auch über pflanzliche Östrogene unterstützen. Diese finden sich primär in Leinsamen und in Soyaprodukten. Auch Schafgabentee und
                    Traubensilberkerze kann hier unterstützend eingesetzt werden.`,
          };
        case "C":
          return {
            title: "Östrogenerhöhung",
            products: "Östro Control, Magnesium, Vitamin D3/K2, Multi, Omega 3",
            text: `Eine Östrogenerhöhung tritt bei Frauen sehr häufig auf. Die Ursachen können in der vermehrten Aufnahme von Umweltgiften liegen,
                    insbesondere Cremes, Lotions, Schminke etc. Naturkosmetik sind die beste Lösung, um einer Vielzahl an Umweltgiften aus dem Weg zu
                    gehen, die über die Haut aufgenommen werden. Auch der Konsum von Leitungswasser ist zu überdenken. Die Universität Freiburg fand
                    heraus, dass das Grundwasser in Deutschland Medikamenten und Hormonrückstände aufweist. Optimalererweise investierst du in gute
                    Wasserfilternalagen oder steigst um auf Wasser aus Glasflaschen. Gewisse Nähr- und Pflanzenstoffe können zudem den Körper unterstützen
                    Östrogene auszuscheiden (dein Darm sollte also gut funktionieren. Hast du den Darmtest schon gemacht?) und sogar der Produktion von
                    Gesundheitsschädlichen Östrogenen vorzubeugen.`,
          };
        case "D":
          return {
            title: "Östrogendominanz bei Progesteronmangel",
            products: "Östro Control, Magnesium, Vitamin D3/K2, Multi, Omega 3",
            text: `Östrogendominanz bei Progesteronmangel ist ein weitverbreitetes Bild bei Frauen, nicht nur um die Wechseljahre. Der Zyklus ist gestört, Stimmungsschwankungen, PMS, Schlafstörungen, Hautunreinheiten, Ödeme u.v.m. kann die Folge sein. Häufig hervorgerufen wird die Situation durch Stress, Schminke, Lotions und anderen Umweltgiften und eine Hormontherapie. Ein gutes Stressmanagement, der Umstieg auf Naturkosmetika, das Meiden von Leitungswasser und Wasser auf Plastikflaschen, sowie eine Unterstützung des Verdauungssystems, um Östrogene auszuscheiden, können deine Symptome verbessern. Auch gewisse Pflanzenstoffe können hier unterstützend eingesetzt werden, um Progesteron zu stimulieren, dein Stressmanagement zu unterstützen und die Östrogene auszuscheiden.`,
          };
        case "E":
          return {
            title: "Testosteronerhöhung",
            products: "Östro Control, Magnesium, Vitamin D3/K2, Multi, Omega 3",
            text: `Eine Testosteronerhöhung kann vor allem in Verbindung mit der Krankheit PCOS auftreten. Begleitet ist PCOS von Vernarbungen an den
                    Eierstöcken, einer Testosteronerhöhung und einer Störung des Kohlenhydratstoffwechsels. In diesem Fall solltest du dich von professionellem
                    medizinischem Personal begleiten lassen. Zur Unterstützung können jedoch auch Nähr- und Pflanzenstoffe eingesetzte werden. Studien
                    zeigen, dass ein täglicher Konsum von 30g Leinsamen täglich Testosteron sinken lässt.`,
          };
        case "F":
          return {
            title: "Testosteronmangel",
            products: "Östro Control, Magnesium, Vitamin D3/K2, Multi, Omega 3",
            text: `Testosteron spielt auch in der weiblichen Gesundheit eine entscheidende Rolle. Es ist wichtig für ein gesundes Nervensystem, die
                    Gefühlslage, das Knochen- und Muskelwachstum. Ein Mangel kann zu Fettaufbau, Müdigkeit und einem Verlust der Libido führen, sowie zu
                    kognitiven Einschränkungen. Ein Mangel kommt häufig durch Stress, weshalb ein gutes Stressmanagement unabdingbar ist. Auch gewisse
                    Kräuter und Nährstoffe können in diesem Fall unterstützend wirken.`,
          };
        case "G":
          return {
            title: "Cortisol Erhöhung",
            products: "Östro Control, Magnesium, Vitamin D3/K2, Multi, Omega 3, Adaptobalance",
            text: `Mit der Cortisolausschüttung reagiert unser Körper auf Stress. In den meisten Fällen gehen die Symptome einher mit Symptomen eines Cortisolmangels, weil nach einer
                    Erhöhung meist der Mangel folgt. Zu hohe Cortisolwerte aufgrund von häufig wiederkehrenden Stressoren können zu zahlreichen negativen Auswirkungen, wie Burn-Out,
                    Schilddrüsenunterfunktion, Insulinresistenz, Diabetes 2, erhöhtes Bauchfett und kognitive Beeinträchtigungen uvm. führen. Vor allem Frauen sind einer Vielzahl an
                    Stressoren, wie z.B. Kinder, Familie, Arbeit, dem Ehefrau- und Mutter Dasein, Sport, etc. ausgesetzt. Neben einem guten Stressmanagement können Kräuter und
                    Nährstoffe unterstützend eingesetzt werden.`,
          };
        case "H":
          return {
            title: "Cortisol Mangel",
            products: "Östro Control, Magnesium, Vitamin D3/K2, Multi, Omega 3, Adaptobalance",
            text: `Ein Cortisol-Mangel ist häufig Resultat einer längerfristigen Aussetzung von Stressoren. In den meisten Fällen gehen die Symptome einher mit Symptomen eines
                    Cortisolmangels , weil nach einer Erhöhung meist der Mangel folgt. Das typische Syndrom ist der Burn-Out, bei dem die Nebenniere nicht mehr in der Lage ist Cortisol zu
                    produzieren. Erschöpfung, Ermüdung und eine geringe Belastbarkeit sind charakteristisch. Die natürliche Tageskurve des Cortisol ist nicht mehr gegeben. Neben einem
                    guten Stressmanagement können Kräuter und Nährstoffe unterstützend eingesetzt werden.`,
            grouped: true,
          };
        default:
          return {};
      }
    }),
  ];
};

const getHormoneResult = (sex, age, answers) => {
  if (sex === "m") return getMenResult(age, answers);
  else return getWomenResult(age, answers);
};

export default getHormoneResult;
