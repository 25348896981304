export default (type) => {
  switch (type) {
    case "detox":
      return "Mit dem folgenden Selbsttest findest du heraus, wie stark deine Belastung mit Umweltgiften ist und wie stark dadurch deine Gene epigenetisch beeinträchtigt werden können. Getestet werden vier Bereiche aus Freizeit, Ernährung, Beruf und Umwelt (ja oder nein-Fragen sind mit immer/nie zu beantworten).";
    case "sleep":
      return "Die folgenden Fragen beziehen sich auf deine üblichen Schlafgewohnheiten der letzten vier Wochen. Beziehe deine Antworten auf die Mehrzahl der Tage und Nächte, die eine Regelmäßigkeit bzgl. deines Schlafes aufweisen. Insgesamt werden 16 Bereiche beurteilt und 7 Komponenten zugeordnet, die jeweils einen Wertebereich von 0-3 annehmen können. Wenn du insg. über 5 Punkte erzielst wirst du in „schlechter“ Schläfer einkategorisiert, bei unter 5 Punkte zählst du zu den „guten“ Schläfern (ja oder nein-Fragen sind mit immer/nie zu beantworten).";
    case "hormone":
      return "Der folgende Selbsttest kann dir einen guten Einblick vermitteln, ob und in welchen Bereichen dein Hormonsystem Störungen aufweist und inwiefern dadurch deine Gene epigenetisch beeinträchtigt werden können (ja oder nein-Fragen sind mit immer/nie zu beantworten).";
    case "darm":
    default:
      return "Der folgend Selbsttest kann dir einen guten Einblick vermitteln, ob und in welchen Bereichen dein Verdauungssystem gestört ist und wie dadurch deine Gene epigenetisch beeinträchtigt werden können (ja oder nein-Fragen sind mit immer/nie zu beantworten).";
  }
};
