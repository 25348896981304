import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        padding: '3px 3px 3px 15px',
        width: '100%',
        minWidth: '500px',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: '1px solid rgba(0, 0, 0, 0.2)',
        borderRadius: '3px',
        margin: '15px 0'
    }
}))

const ShopItem = ({ title, link }) => {
    const classes = useStyles()

    return (
        <div className={classes.wrapper}>
            <Typography variant="h6">{title}</Typography>
            <Button size="small" onClick={() => {}} color="secondary" variant="contained">
                <a style={{ color: 'inherit', textDecoration: 'none' }} target="_blank" href={link}>weiter zum Artikeln</a>
            </Button>
        </div>
    )
}

export default ShopItem