import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ShopItem from "./ShopItem";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import shopElements from "../../../helpers/shopElements";

const useStyles = makeStyles((theme) => ({
  modal: {
    "& > div > div": {
      overflow: "inherit",
    },
  },
  actions: {
    justifyContent: "center",
  },
  inputsWrapper: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const ShopModal = ({ open, handleClose, testType, result }) => {
  const classes = useStyles();
  const productsSet = new Set();

  if (result && result?.products !== "-") {
    Array.isArray(result)
      ? result.forEach((item) => {
          item?.products?.split(",").forEach((product) => productsSet.add(product.trim()));
        })
      : result.products.split(",").forEach((product) => productsSet.add(product.trim()));
  }

  return (
    <Dialog
      className={classes.modal}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      BackdropProps={{
        style: {
          backgroundColor: "rgba(126, 121, 62, 0.38)",
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h5" align="center">
          Empfohlene Produkte
        </Typography>
      </DialogTitle>
      <DialogContent>
        {productsSet.size !== 0 ? (
          [...productsSet].map((item) => <ShopItem key={item} title={item} link={shopElements[item]} />)
        ) : (
          <Typography variant="h6" align="center">
            Sie benötigen keine Produkte
          </Typography>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          onClick={handleClose}
          style={{ marginBottom: "15px", height: "42px" }}
          variant="contained"
          color="inherit"
        >
          Zumachen
        </Button>
        {/* <Button style={{ width: '200px', marginBottom: '15px' }} size="large" onClick={() => {}} color="secondary" variant="contained">
                    <a style={{ color: 'inherit', textDecoration: 'none' }} target="_blank" href="https://natugena.de/artikeluebersicht/Alle-Produkte.aspx?WPParams=43CCD7D4B5DDE6B7C2E0B1CDE1C8B6B7949A9876BBD3E7D5AFBCCAD5CCD2D7A3CED5C4B8B6D3B1DEBFC5E7C4E1B6B8D9C3BCDED9E79EA186">weiter zum Shop</a>
                </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default ShopModal;
