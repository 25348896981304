import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import capitalizeFirstLetter from '../../../helpers/capitalizeFirstLetter'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '50px 0'
    },
    question: { 
        marginTop: '25px', 
        padding: '30px', 
        maxWidth: '80%', 
        textAlign: 'center'
    }
}));

const TestQuestion = ({ teil, title, question, testType }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Typography color="textSecondary" variant="h5">{capitalizeFirstLetter(testType)} Test: {teil}</Typography>
            <Typography align="center" color="textPrimary" variant="h4" style={{ marginTop: '5px' }}>{title}</Typography>
            <Typography color="textPrimary" variant="h5" style={{ marginTop: '25px', padding: '30px', maxWidth: '80%', textAlign: 'center' }}>
                {question}
            </Typography>
        </div>
    )
}

export default TestQuestion