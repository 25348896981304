export default {
  "Mukosa Repair": "https://epi-genes.com/artikeldetails/Mukosa-Repair.aspx",
  "EPI Herbs": "https://epi-genes.com/artikeldetails/EPI-Herbs.aspx",
  "Mega N*Zyme": "https://epi-genes.com/artikeldetails/Mega-N-Zyme.aspx",
  Gasta: "https://epi-genes.com/artikeldetails/GaSta.aspx",
  Adaptobalance: "https://epi-genes.com/artikeldetails/AdaptoBalance.aspx",
  "Mono Detox": "https://epi-genes.com/artikeldetails/MonoDiTox.aspx",
  Tritox: "https://epi-genes.com/artikeldetails/TriTox.aspx",
  Magnesium: "https://epi-genes.com/artikeldetails/Magnesium-Malat-Glycinat.aspx",
  Somniasan: "https://epi-genes.com/artikeldetails/SomniaSan.aspx",
  "Östro Control": "https://epi-genes.com/artikeldetails/Oestro-Control.aspx",
  "Vitamin D3/K2": "https://epi-genes.com/artikeldetails/Vitamin-D3-K2.aspx",
  Multi: "https://epi-genes.com/artikeldetails/SpektrA-Z.aspx",
  "Omega 3": "https://epi-genes.com/artikeldetails/Omega-3-Vegan.aspx",
};
