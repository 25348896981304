import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import Checkbox from './Checkbox'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    checkboxWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        minWidth: '600px',
        width: '80%',
        // marginTop: '20px'
    },
    buttonsWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '40px',
        '& > *': {
            margin: theme.spacing(2),
        },
        width: '300px'
    }
}));


const TestActions = ({ activeAnswers, isNext, handleNext, answers, handleBack, onCheckboxClick }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.checkboxWrapper}>
                {answers.map(({answer}, i) => (
                    <Checkbox key={answer} label={answer} onClick={() => onCheckboxClick(i)} active={activeAnswers.includes(i)} />
                ))}
            </div>
            <div className={classes.buttonsWrapper}>
                <Button
                    onClick={handleBack}
                    fullWidth
                    size="large"
                    variant="outlined"
                    color="primary"
                >
                    Zurück
                </Button>
                <Button onClick={handleNext} disabled={!isNext} fullWidth size="large" variant="contained" color="secondary">Weiter</Button>
            </div>
        </div>
    )
}

export default TestActions