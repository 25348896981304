import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '15px'
    },
}));

const Nav = ({ teils, activeTeil, showResult }) => {
    const classes = useStyles();


    return (
        <div className={classes.root}>
            <Breadcrumbs maxItems={10} separator={<NavigateNextIcon fontSize="default" />} aria-label="breadcrumb">
                <Typography variant="h6" color="textPrimary">Willkommen</Typography>
                {teils.map((teil, i) => (
                    <Typography key={teil} variant="h6" color={activeTeil >= i ? "textPrimary" : "textSecondary"}>{teil}</Typography>
                ))}
                <Typography variant="h6" color={showResult ? "textPrimary" : "textSecondary"}>Resultat</Typography>
            </Breadcrumbs>
        </div>
    );
}

export default Nav