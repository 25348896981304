const detox = {
    'Teil 1': {
        title: 'UMWELTGIFTBELASTUNG DURCH REISEN UND ERHOLUNG',
        questions: [
            {
                question: 'Besuchst du häufig Parks, Golfplätze oder andere Freizeit- und Erholungsgebiete, die mit Herbiziden, Pestiziden und Fungiziden belastet sein könnten?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Reist du auf dem Luftweg?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Läufst oder fährst du mit dem Fahrrad auf befahrenen Straßen zur Arbeit?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Wirst du krank bei Camping, Wandern oder Reisen?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Bist du aufgrund deines Hobbys giftigen Chemikalien ausgesetzt (z.B. Farben, Chemikalien zur Fotoentwicklung, Epoxidklebern, Lacken, Klebern und so weiter)?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
        ]
    },
    'Teil 2': {
        title: 'UMWELTGIFTE IN DER HÄUSLICHEN UND BERUFLICHEN UMGEBUNG',
        questions: [
            {
                question: 'Lebst du in einer Wohnung, die vor circa 1978 gebaut wurde oder in einem mobilen Zuhause oder Hausboot oder Ähnlichem?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Ist deine Wohnung oder dein Arbeitsplatz mit neuen Baumaterialien oder neuen Möbeln ausgestattet (zum Beispiel Farben, Laminat, Holzfaserplatten, neuen Teppichen, Bettzeug, sonstigen Möbeln usw.?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Gibt es Anzeichen von Schimmel oder Wasserschäden (zum Beispiel brüchige Farben, Brüche in den Decke, verrottete Dämmung oder Schaumstoff, sichtbarer Schimmel oder anlaufende Fenster, Zwischendecken usw.) in der Wohnung oder an deinem Arbeitsplatz?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Bist du giftigen Substanzen (wie zum Beispiel behandeltem Holz, Bleifarben, Spänen, Dunst, Quecksilber aus einem zerbrochenem Thermometer oder fluoreszierenden Glühbirnen usw.) in deiner Arbeit oder zu Hause ausgesetzt?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Bist du zu Hause oder in der Arbeit konventionellen Reinigungschemikalien, Desinfektionsmitteln, Duftkerzen oder anderen Duftprodukten ausgesetzt?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
        ]
    },
    'Teil 3': {
        title: 'UMWELTGIFTE IN DER HÄUSLICHEN UND BERUFLICHEN UMGEBUNG',
        questions: [
            {
                question: 'Lebst oder arbeitest du in der Nähe eines industriellen Umweltverschmutzers (z.B. Autobahn, Fabrik, Tankstelle, Müllverbrennungsanlage, Kraftwerk ...)?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Lebst oder arbeitest du in der Nähe einer elektromagnetischen Strahlung (z:b. Handymasten, Hochspannungsleitungen ...)?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Lebst oder arbeitest du in deiner landwirtschaftlichen Gegend oder einer anderen Region, in der du Herbiziden, Pestiziden oder Fungiziden ausgesetzt bist?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Hast du Feuerholz, Propan oder Gasöfen oder ähnliche Vorrichtungen zu Hause oder in der Arbeit?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Lebst oder arbeitest du in einem gut abgedichteten, schlecht gelüfteten Gebäude?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
        ]
    },
    'Teil 4': {
        title: 'UMWELTGIFTBELASTUNG DURCH ESSEN UND TRINKEN',
        questions: [
            {
                question: 'Isst du konventionell angebautes (nicht bio) oder genmanipuliertes Obst und Gemüse?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Konsumierst du tierische Produkte (z.B. Fleisch, Geflügel, Milchprodukte, Eier) aus konventioneller Aufzucht (nicht bio)?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Isst du Fisch und Meeresfrüchte aus Dosen oder aus der Aufzucht (nicht Wildfang)?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Nimmst du verarbeitete Nahrung (versetzt mit künstlichen Süß-, Duft-, Konservierungs- und Farbstoffen), Frittiertes und Fast Food zu dir?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Trinkst du Wasser aus einer Quelle, einem Brunnen, einem Wasserspeicher oder aus alten Leitungen?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Trinkst du Limonaden oder andere Getränke mit natürlichen oder raffinierten Süßstoffen (Fruktose-Mais-Sirup, Rohrzucker, Agavennektar, unverdünnte Säfte usw.) oder künstlichen Süßstoffen (z.B. Aspartam)?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            }
        ]
    },
    'Teil 5': {
        title: 'UMWELTGIFTE IN DER MEDIZINISCHEN VERSORGUNG UND DER KÖRPERPFLEGE',
        questions: [
            {
                question: 'Benutzt du Körperpflegeprodukten wie Lotions, Feuchtigkeitscremes, Shampoos, Conditioner, Rasiercremes und Seifen, die nicht von einem biologischen Naturhersteller kommen?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Reagierst du empfindlich auf Rauch, Parfum, Düfte, Reinigungsprodukte, Benzin oder andere Gerüche?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Rauchst du oder bist du häufig Passivrauch ausgesetzt?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Hast du in der Vergangenheit vermehrt Alkohol und Drogen konsumiert oder verschreibungspflichtige Medikamente eingenommen?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Zeigst du ungewöhnliche Reaktionen gegenüber Anästhesie oder Medikamenten?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Hast du entfernte Wurzelkanäle, Gold- oder Silberfüllungen, Amalgam, Kronen, Versiegelungen, Zahnprothesen, Zahnspangen, Implantate (nicht Keramik) oder trägst du einen Mundschutz?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Leidest du an Allergien?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Hast du irgendwelche künstlichen Materialien in deinem Körper (Implantate, Gelenke, Schrauben oder Platten...)?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            },
            {
                question: 'Lebst du einen stressigen Lifestyle oder hast du in der Vergangenheit ein traumatisches Ereignis erlebt?',
                answers: ['Nie', 'in der Vergangenheit', 'Manchmal', 'Ja']
            }
        ]
    }
}

export default detox