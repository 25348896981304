export const hormoneMen = {
    'A': {
        title: 'PROGESTERONMANGEL',
        question: 'Leidest du an den folgenden Beschwerden? Bitte ankreuzen.',
        answers: [
            {
                answer: 'Verminderte Libido',
            },
            {
                answer: 'Erhöhter Harndrang',
            },
            {
                answer: 'Prostataprobleme',
            },
            {
                answer: 'Verminderter Harnstrahl',
            },
            {
                answer: 'Schlafstörungen',
            },
            {
                answer: 'Knochenschwund',
            },
        ]
    },
    'B': {
        title: 'ÖSTROGENMANGEL',
        question: 'Leidest du an den folgenden Beschwerden? Bitte ankreuzen.',
        answers: [
            {
                answer: 'Hitzewallungen',
            },
            {
                answer: 'Nachtschweiß',
            },
            {
                answer: 'Gleichgültigkeit',
            },
            {
                answer: 'Depression',
            },
            {
                answer: 'Knochenschwund',
            },
            {
                answer: 'Konzentrationsstörungen',
            },
        ]
    },
    'C': {
        title: 'ÖSTROGENERHÖHUNG',
        question: 'Leidest du an den folgenden Beschwerden? Bitte ankreuzen.',
        answers: [
            {
                answer: 'Prostataprobleme',
            },
            {
                answer: 'Verminderter Harnstrahl',
            },
            {
                answer: 'Ängstlichkeit',
            },
            {
                answer: 'Reizbarkeit',
            },
            {
                answer: 'Verminderte Libido',
            },
            {
                answer: 'Kopfschmerzen',
            },
            {
                answer: 'Brustdrüsenvergrößerung',
            },
            {
                answer: 'Gewichtszunahme, insbesondere an den Hüften',
            },
            {
                answer: 'Nervosität',
            },
            {
                answer: 'Erhöhter Harndrang',
            },
        ]
    },
    'D': {
        title: 'TESTOSTERONERHÖHUNG',
        question: 'Leidest du an den folgenden Beschwerden? Bitte ankreuzen.',
        answers: [
            {
                answer: 'Verlust der Kopfbehaarung',
            },
            {
                answer: 'Fettige Haut',
            },
            {
                answer: 'Erhöhte Aggression',
            },
            {
                answer: 'Reizbarkeit',
            },
            {
                answer: 'Akne',
            },
            {
                answer: 'Ängstlichkeit',
            },
        ]
    },
    'E': {
        title: 'TESTOSTERONMANGEL',
        question: 'Leidest du an den folgenden Beschwerden? Bitte ankreuzen.',
        answers: [
            {
                answer: 'Depression',
            },
            {
                answer: 'Geistige Leistungsminderung',
            },
            {
                answer: 'Müdigkeit',
            },
            {
                answer: 'Blutarmut',
            },
            {
                answer: 'Verminderte Vitalität und Ausdauer',
            },
            {
                answer: 'Ausgebranntsein',
            },
            {
                answer: 'Schlafstörungen',
            },
            {
                answer: 'Osteoporose/Knochenschwund',
            },
            {
                answer: 'Insulinresistenz',
            },
            {
                answer: 'Zunahme des Bauchfettes',
            },
            {
                answer: 'Verminderte Muskelmasse',
            },
            {
                answer: 'Dünne trockene Haut',
            },
            {
                answer: 'Konzentrationsschwäche',
            },
            {
                answer: 'Schmerzen',
            },
            {
                answer: 'Verminderte Libido/Erektionsstörungen',
            },
            {
                answer: 'Prostataprobleme',
            },
            {
                answer: 'Verminderter Harnstrahl',
            },
            {
                answer: 'Erhöhter Harndrang',
            },
            {
                answer: 'Inkontinenz',
            },
            {
                answer: 'Brustdrüsenvergrößerung',
            },
        ]
    },
    'F': {
        title: 'CORTISOLERHÖHUNG',
        question: 'Leidest du an den folgenden Beschwerden? Bitte ankreuzen.',
        answers: [
            {
                answer: 'Gefühl, von einer Aufgabe zur nächsten zu hetzen',
            },
            {
                answer: 'Zunahme von Bauchfett',
            },
            {
                answer: 'Heißhunger auf Süßes',
            },
            {
                answer: 'Bluthochdruck oder Herzrasen',
            },
            {
                answer: 'Verdauungsstörungen oder Darmprobleme',
            },
            {
                answer: 'Verminderte Libido',
            },
            {
                answer: 'Depression',
            },
            {
                answer: 'Gedächtnisschwäche oder mangelnde Konzentration',
            },
            {
                answer: 'Müdigkeit, aber Anspannung',
            },
            {
                answer: 'Ängstlichkeit und Sorgen',
            },
            {
                answer: 'Reizbarkeit',
            },
            {
                answer: 'Schlafstörungen',
            },
            {
                answer: 'Knochenschwund',
            },
            {
                answer: 'Verminderte Muskelmasse',
            },
            {
                answer: 'Dünne Haut oder Hautunreinheiten',
            },
        ]
    },
    'G': {
        title: 'CORTISOLMANGEL',
        question: 'Leidest du an den folgenden Beschwerden? Bitte ankreuzen.',
        answers: [
            {
                answer: 'Übermäßiges Schwitzen',
            },
            {
                answer: 'Verminderte Problembewältigung',
            },
            {
                answer: 'Übelkeit, Erbrechen oder Durchfälle',
            },
            {
                answer: 'Verlangen nach Salz',
            },
            {
                answer: 'Schlafstörungen (vor allem zwischen 1 und 4 Uhr)',
            },
            {
                answer: 'Trockene Haut',
            },
            {
                answer: 'Verminderte Konzentration',
            },
            {
                answer: 'Vermehrt braune Flecken auf der Haut',
            },
            {
                answer: 'Blutzuckerschwankungen',
            },
            {
                answer: 'Energielosigkeit',
            },
            {
                answer: 'Muskel- oder Gelenkschmerzen',
            },
            {
                answer: 'Arthritis',
            },
            {
                answer: 'Allergien, chronischer Husten, Asthma',
            },
            {
                answer: 'Gefühl, immer unter Stress zu stehen',
            },
            {
                answer: 'Niedriger Blutdruck',
            },
            {
                answer: 'Herzklopfen',
            },
            {
                answer: 'Frösteln oder Kältegefühl',
            },
        ]
    }
}

export const hormoneWomen = {
    'A': {
        title: 'PROGESTERONMANGEL',
        question: 'Leidest du an den folgenden Beschwerden? Bitte ankreuzen.',
        answers: [
            {
                answer: 'Unruhezustände oder PMS (Prämenstruelles Syndrom)',
            },
            {
                answer: 'Zyklisch auftretende Kopfschmerzen',
            },
            {
                answer: 'Schmerzende und/oder geschwollene Brüste',
            },
            {
                answer: 'Fehlgeburten',
            },
            {
                answer: 'Starke und schmerzhafte Periode',
            },
            {
                answer: 'Schwellungen/Flüssigkeitsansammlungen, insbesondere an den Knöcheln und Bauch',
            },
            {
                answer: 'Verminderte Libido',
            },
            {
                answer: 'Unerfüllter Kinderwunsch',
            },
            {
                answer: 'Ängstlichkeit',
            },
            {
                answer: 'Schlafstörungen',
            },
            {
                answer: 'Juckende Beine, insbesondere in der Nacht',
            },
        ]
    },
    'B': {
        title: 'ÖSTROGENMANGEL',
        question: 'Leidest du an den folgenden Beschwerden? Bitte ankreuzen.',
        answers: [
            {
                answer: 'Gedächtnisschwäche'
            },
            {
                answer: 'Psychische Labilität, insbesondere Ängste oder Trägheit'
            },
            {
                answer: 'Kleine Fältchen, insbesondere um die Augen, Mund und an den Händen'
            },
            {
                answer: 'Nachtschweiß oder Hitzewallungen'
            },
            {
                answer: 'Vaginale Trockenheit/schmerzhafter Verkehr'
            },
            {
                answer: 'Blutungsunregelmäßigkeiten'
            },
            {
                answer: 'Lethargische Depression'
            },
            {
                answer: 'Knochenschwund'
            },
            {
                answer: 'Inkontinenz'
            },
            {
                answer: 'Hängende Brüste, die an Größe verloren haben'
            },
            {
                answer: 'schmerzende Gelenke'
            },
            {
                answer: 'Häufige Blasenentzündung'
            },
        ]
    },
    'C': {
        title: 'ÖSTROGENERHÖHUNG',
        question: 'Leidest du an den folgenden Beschwerden? Bitte ankreuzen.',
        answers: [
            {
                answer: 'Schwellungen/Wassereinlagerungen'
            },
            {
                answer: 'Starke oder schmerzhafte Blutungen'
            },
            {
                answer: 'Gewichtszunahme, insbesondere an der Hüfte'
            },
            {
                answer: 'Schmerzempfindliche Brüste'
            },
            {
                answer: 'Stimmungsschwankungen oder PMS'
            },
            {
                answer: 'Depression oder leichte Reizbarkeit'
            },
            {
                answer: 'Gebährmutterhalsveränderungen'
            },
            {
                answer: 'Migräne'
            },
            {
                answer: 'Schlafstörungen'
            },
            {
                answer: 'Weinerlichkeit wegen Kleinigkeiten'
            },
            {
                answer: 'Ängstlichkeit'
            },
            {
                answer: 'Plötzliches Rotwerden im Gesicht'
            },
            {
                answer: 'Zysten ind en Brüsten/Eierstöcken'
            },
            {
                answer: 'Darmprobleme'
            },
            {
                answer: 'Probleme mit der Galle'
            },
        ]
    },
    'E': {
        title: 'TESTOSTERONERHÖHUNG',
        question: 'Leidest du an den folgenden Beschwerden? Bitte ankreuzen.',
        answers: [
            {
                answer: 'Übermäßige Behaarung im Gesicht, auf der Brust, an den Armen',
            },
            {
                answer: 'Akne, fettige Haut und/oder fettige Haare',
            },
            {
                answer: 'Haarausfall, ggf. Geheimratsecken',
            },
            {
                answer: 'Verfärbung der Achselhöhlen (dunkler und dicker)',
            },
            {
                answer: 'Blutzuckerschwankungen',
            },
            {
                answer: 'Leichte Reizbarkeit und Aggressivität',
            },
            {
                answer: 'Längere Zyklen',
            },
            {
                answer: 'Eierstockzysten oder Polyzystisches Ovarialsyndrom (PCOS)',
            },
            {
                answer: 'Schmerzen in der Zyklusmitte',
            },
            {
                answer: 'Unfruchtbarkeit',
            },
            {
                answer: 'Erhöhte Triglyceridwerte im Blut',
            },
        ]
    },
    'F': {
        title: 'TESTOSTERONMANGEL',
        question: 'Leidest du an den folgenden Beschwerden? Bitte ankreuzen.',
        answers: [
            {
                answer: 'Herzklopfen'
            },
            {
                answer: 'Vaginale Trockenheit'
            },
            {
                answer: 'Schmerzhafter Verkehr'
            },
            {
                answer: 'Verlust emotionaler Stärke'
            },
            {
                answer: 'Müdigkeit'
            },
            {
                answer: 'Inkontinenz'
            },
            {
                answer: 'Knochenschwund'
            },
            {
                answer: 'Verminderte Muskelmasse'
            },
            {
                answer: 'Gedächtnis-/Konzentrationsschwäche'
            },
            {
                answer: 'Fibromyalgie'
            },
            {
                answer: 'Dünne Haut'
            },
            {
                answer: 'Verminderte Libido'
            },
        ]
    },
    'G': {
        title: 'CORTISOLERHÖHUNG',
        question: 'Leidest du an den folgenden Beschwerden? Bitte ankreuzen.',
        answers: [
            {
                answer: 'Gefühl, von einer Aufgabe zur nächsten zu hetzen',
            },
            {
                answer: 'Zunahme von Bauchfett',
            },
            {
                answer: 'Heißhunger auf Süßes',
            },
            {
                answer: 'Bluthochdruck oder Herzrasen',
            },
            {
                answer: 'Verdauungsstörungen oder Darmprobleme',
            },
            {
                answer: 'Verminderte Libido',
            },
            {
                answer: 'Depression',
            },
            {
                answer: 'Gedächtnisschwäche oder mangelnde Konzentration',
            },
            {
                answer: 'Müdigkeit, aber Anspannung',
            },
            {
                answer: 'Ängstlichkeit und Sorgen',
            },
            {
                answer: 'Reizbarkeit',
            },
            {
                answer: 'Schlafstörungen',
            },
            {
                answer: 'Knochenschwund',
            },
            {
                answer: 'Verminderte Muskelmasse',
            },
            {
                answer: 'Dünne Haut oder Hautunreinheiten',
            },
        ]
    },
    'H': {
        title: 'CORTISOLMANGEL',
        question: 'Leidest du an den folgenden Beschwerden? Bitte ankreuzen.',
        answers: [
            {
                answer: 'Übermäßiges Schwitzen',
            },
            {
                answer: 'Verminderte Problembewältigung',
            },
            {
                answer: 'Übelkeit, Erbrechen oder Durchfälle',
            },
            {
                answer: 'Verlangen nach Salz',
            },
            {
                answer: 'Schlafstörungen (vor allem zwischen 1 und 4 Uhr)',
            },
            {
                answer: 'Trockene Haut',
            },
            {
                answer: 'Verminderte Konzentration',
            },
            {
                answer: 'Vermehrt braune Flecken auf der Haut',
            },
            {
                answer: 'Blutzuckerschwankungen',
            },
            {
                answer: 'Energielosigkeit',
            },
            {
                answer: 'Muskel- oder Gelenkschmerzen',
            },
            {
                answer: 'Arthritis',
            },
            {
                answer: 'Allergien, chronischer Husten, Asthma',
            },
            {
                answer: 'Gefühl, immer unter Stress zu stehen',
            },
            {
                answer: 'Niedriger Blutdruck',
            },
            {
                answer: 'Herzklopfen',
            },
            {
                answer: 'Frösteln oder Kältegefühl',
            },
        ]
    }
}
