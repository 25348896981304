const getResultDarm = (answers) => {
  let answersTotal = answers.map((teilAnswers) => {
    return teilAnswers.reduce((sum, current) => sum + current, 0);
  });

  let answersNormalized = answersTotal.map((sum) => {
    if (sum < 5) return "low";
    if (sum >= 5 && sum <= 9) return "moderate";
    if (sum > 9) return "high";
    return;
  });

  if (
    answersNormalized.includes("high") ||
    answersNormalized.filter((item) => item === "moderate").length > 1
  ) {
    return {
      title: "",
      products: "Adaptobalance, Mukosa Repair, Mega N*Zyme",
      text: `Aufgrund deiner Symptome scheinst du ein eingeschränkt funktionierendes Verdauungssystem zu haben. Die Aktivität deiner
            Darmbakterien kann sich epigenetisch negativ auf deine Genexpression auswirken. Zur optimalen Wiederherstellung deiner
            Darmgesundheit empfehlen wir mit einem unserer Epigenetik Coaches zusammenzuarbeiten, zusammenzuarbeiten, um eine Darmsanierung in
            vier Schritten durchzuführen.
            Dazu gehört neben einer Eliminierungsdiät der Aufbau deines Mikrobioms in Form von Probiotikum auch der Aufbau deiner
            Schleimhaut, die Unterstützung deiner Verdauung und die Beseitigung möglicher Dysbiosen.`,
    };
  } else if (answersNormalized.indexOf("moderate") > 0) {
    return {
      title: "",
      products: "Adaptobalance, Mukosa Repair, Mega N*Zyme",
      text: `Aufgrund deiner Symptome scheinst du ein eingeschränkt funktionierendes Verdauungssystem zu haben. Die Aktivität deiner
            Darmbakterien kann sich epigenetisch negativ auf deine Genexpression auswirken. Zur optimalen Wiederherstellung deiner
            Darmgesundheit empfehlen wir mit einem unserer Epigenetik Coaches zusammenzuarbeiten, zusammenzuarbeiten, um eine Eliminierungsdiät
            durchzuführen. Dazu gehört die Bestimmung der für dich ungünstigen Lebensmittel und deren Eliminierung für mindestens vier
            Wochen.`,
    };
  } else if (answersNormalized.indexOf("moderate") === 0) {
    return {
      title: "",
      products: "Mukosa Repair, EPI Herbs, Mega N*Zyme, Gasta",
      text: `Aufgrund deiner Symptome lässt sich eine eingeschränkt funktionierende Magentätigkeit erkennen. Darunter kann auch dein
            Mikrobiom leiden und deine Darmbakterien können sich negativ auf deine Genexpression auswirken. Da die Magensäure vom
            vegetativem Nervensystem angetrieben wird, sollte demnach dein Fokus auf stressausgleichenden Maßnahmen liegen. Dazu gehört
            auch die Eliminierung für dich Stress verursachender Lebensmittel. Wir empfehlen daher mit einem unserer Precision Performance
            Coaches zusammen zuarbeiten, um deiner Gesundheit wieder neuen Schwung zu verhelfen.`,
    };
  } else {
    return {
      title: "All good",
      products: "-",
      text: "-",
    };
  }

  // console.log(answersTotal)
  // console.log(answersNormalized)

  // if (answersNormalized.includes('high')) {
  //     return {
  //         title: '',
  //         products: 'Adaptobalance, Mukosa Repair, Mega N*Zyme',
  //         text: `A - Aufgrund deiner Symptome scheinst du ein eingeschränkt funktionierendes Verdauungssystem zu haben. Die Aktivität deiner
  //         Darmbakterien kann sich epigenetisch negativ auf deine Genexpression auswirken. Zur optimalen Wiederherstellung deiner
  //         Darmgesundheit empfehlen wir mit einem unserer Epigenetik Coaches zusammenzuarbeiten, zusammenzuarbeiten, um eine Darmsanierung in
  //         vier Schritten durchzuführen.
  //         Dazu gehört neben einer Eliminierungsdiät der Aufbau deines Mikrobioms in Form von Probiotikum auch der Aufbau deiner
  //         Schleimhaut, die Unterstützung deiner Verdauung und die Beseitigung möglicher Dysbiosen.`,
  //     }
  // } else if (answersNormalized.indexOf('moderate') === 0 || answersNormalized.indexOf('high') === 0) {
  //     return {
  //         title: '',
  //         products: 'Mukosa Repair, EPI Herbs, Mega N*Zyme, Gasta',
  //         text: `C - Aufgrund deiner Symptome lässt sich eine eingeschränkt funktionierende Magentätigkeit erkennen. Darunter kann auch dein
  //         Mikrobiom leiden und deine Darmbakterien können sich negativ auf deine Genexpression auswirken. Da die Magensäure vom
  //         vegetativem Nervensystem angetrieben wird, sollte demnach dein Fokus auf stressausgleichenden Maßnahmen liegen. Dazu gehört
  //         auch die Eliminierung für dich Stress verursachender Lebensmittel. Wir empfehlen daher mit einem unserer Precision Performance
  //         Coaches zusammen zuarbeiten, um deiner Gesundheit wieder neuen Schwung zu verhelfen.`
  //     }
  // } else if (answersNormalized.indexOf('moderate') > 0) {
  //     return {
  //         title: '',
  //         products: 'Adaptobalance, Mukosa Repair, Mega N*Zyme',
  //         text: `B - Aufgrund deiner Symptome scheinst du ein eingeschränkt funktionierendes Verdauungssystem zu haben. Die Aktivität deiner
  //         Darmbakterien kann sich epigenetisch negativ auf deine Genexpression auswirken. Zur optimalen Wiederherstellung deiner
  //         Darmgesundheit empfehlen wir mit einem unserer Epigenetik Coaches zusammenzuarbeiten, zusammenzuarbeiten, um eine Eliminierungsdiät
  //         durchzuführen. Dazu gehört die Bestimmung der für dich ungünstigen Lebensmittel und deren Eliminierung für mindestens vier
  //         Wochen.`
  //     }
  // } else if (answersNormalized.filter(item => item === 'moderate').length > 1) {
  //     return {
  //         title: '',
  //         products: 'Adaptobalance, Mukosa Repair, Mega N*Zyme',
  //         text: `A - Aufgrund deiner Symptome scheinst du ein eingeschränkt funktionierendes Verdauungssystem zu haben. Die Aktivität deiner
  //         Darmbakterien kann sich epigenetisch negativ auf deine Genexpression auswirken. Zur optimalen Wiederherstellung deiner
  //         Darmgesundheit empfehlen wir mit einem unserer Epigenetik Coaches zusammenzuarbeiten, zusammenzuarbeiten, um eine Darmsanierung in
  //         vier Schritten durchzuführen.
  //         Dazu gehört neben einer Eliminierungsdiät der Aufbau deines Mikrobioms in Form von Probiotikum auch der Aufbau deiner
  //         Schleimhaut, die Unterstützung deiner Verdauung und die Beseitigung möglicher Dysbiosen.`,
  //     }
  // } else {
  //     return {
  //         title: 'All good',
  //         products: '-',
  //         text: '-'
  //     }
  // }
};

const getResultSleep = (answers) => {
  let answersTotal = answers.map((teilAnswers) => {
    return teilAnswers.reduce((sum, current) => sum + current, 0);
  });

  let answersNormalized = answersTotal.map((item, i) => {
    if (i === 4) {
      if (item === 0) return item;
      if (item <= 9) return 1;
      if (item <= 18) return 2;
      if (item <= 27) return 3;
    }
    if (i === 6) {
      return Math.ceil(item / 2);
    }
    return item;
  });

  let sum = answersNormalized.reduce((sum, current) => sum + current, 0);

  if (sum <= 5) {
    return {
      title: "Guter Schläfer",
      products: "Magnesium, Somniasan",
      text: `Du scheinst ein guter Schläfer zu sein. Guter Schlaf unterstützt nicht nur deine Regeneration sondern fördert die Fettverbrennung, die Produktion von Testosteron und deine mentale und
            physische Leistungsfähigkeit. Um deinen Schlaf weiter zu unterstützen empfehlen wir dir unseren Schlafguide als kostenlosen Download.`,
    };
  } else {
    return {
      title: "Schlechter Schläfer",
      products: "Magnesium, Somniasan",
      text: `Dein Schlafverhalten ist nicht optimal und kann sich negativ auf deine Gesundheit, deine Leistung und deine Stimmung auswirken. Sogar die Fettverbrennung kann darunter leiden. Man hat
            festgestellt, dass schlechter Schlaf die Fettverbrennung um bis zu 20% einschränken kann. Auch dein Hormonsystem kann darunter leiden und viele weitere damit verbundene
            gesundheitlichen Belange.
            Um deinen Schlaf wieder neuen Schwung zu verleihen empfehlen wir dir die Schritte in unserem Schlafguide Schritt für Schritt durchzuführen.`,
    };
  }
};

const getResultDetox = (answers) => {
  let answersTotal = answers.map((teilAnswers) => {
    return teilAnswers.reduce((sum, current) => sum + current, 0);
  });

  let sum = answersTotal.reduce((sum, current) => sum + current, 0);

  if (sum <= 10) {
    return {
      title: "Geringe Exposition",
      products: "-",
      text: `Du hast eine geringe toxische Exposition. Das bedeutet, dass du viel Wert auf einen gesunden Lifestyle legst und Giftstoffe aktiv meidest. Eine geringe Exposition geht jedoch nicht immer
            einher mit einem gut funktionierendem Entgiftungssystem. Wenn Symptome vorliegen wie Schwindel, Brain-Fog oder chronische Erkrankungen sollte dies umgehend mit einem
            Umweltmediziner abgeklärt werden.`,
    };
  } else if (sum <= 20) {
    return {
      title: "Moderate Exposition",
      products: "Mono Detox, Tritox",
      text: `Eine moderate Umweltgift-Exposition zeigt, dass dein Körper tagtäglich vermehrt Giftstoffen ausgesetzt ist und dass deine Entgiftungssysteme kontinuierlich gefordert sind. Diese Gifte können sich negativ auf dein Hormonsystem, dein
            Energiesystem und dein Nervensystem auswirken. Wichtig dabei ist, dass deine Entgiftungsleistung gut funktioniert. Auch dein Darm sollte einwandfrei arbeiten, so dass die Gifte über den Stuhl
            ausgeschieden werden können. In unserem Detox Guide findest du mehrere Anregungen, um im Alltag Giftstoffe zu meiden. Folgende Nährstoffe können dich bei deinem Detox
            unterstützen.`,
    };
  } else if (sum > 20) {
    return {
      title: "Starke Exposition",
      products: "Mono Detox, Tritox",
      text: `Du bist einer starken Umweltgiftexposition ausgesetzt und dein Körper und dein Entgiftungssystem müssen tagtäglich Höchstleistungen vollbringen. Diese Gifte können sich negativ auf dein
            Hormonsystem, dein Energiesystem und dein Nervensystem auswirken. Wichtig dabei ist, dass deine Entgiftungsleistung gut funktioniert. Auch dein Darm sollte einwandfrei arbeiten, so dass
            die Gifte über den Stuhl ausgeschieden werden können. In unserem Detox Guide findest du mehrere Anregungen, um im Alltag Giftstoffe zu meiden. Folgende Nährstoffe können dich bei
            deinem Detox unterstützen.`,
    };
  }
};

const getResult = (testType, answers) => {
  switch (testType) {
    case "darm":
      return getResultDarm(answers);
    case "sleep":
      return getResultSleep(answers);
    case "detox":
      return getResultDetox(answers);
    default:
      return getResultDarm(answers);
  }
};

export default getResult;
