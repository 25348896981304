import React, { useState } from 'react';
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import formIcon from '../../../assets/formIcon.png'
import { startModalSchema } from '../../../helpers/validation'

const useStyles = makeStyles((theme) => ({
    img: {
        width: '200px',
        alignSelf: 'center',
        marginTop: '-60px',
        zIndex: 10000
    },
    modal: {
        '& > div > div': {
            overflow: 'inherit'
        }
    },
    actions: {
        justifyContent: 'center'
    },
    inputsWrapper: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    }
}));

const StartForm = ({ open, handleClose, age, setAge, sex, setSex, onSubmit }) => {
    const classes = useStyles()
    const [showForm, setShowForm] = useState(false)

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            tel: ''
        },
        validationSchema: startModalSchema,
        onSubmit: values => {
            const { email, tel, firstName, lastName } = values

            onSubmit({ firstName, lastName, email, age, tel })
            handleClose()
        },
    })

    return (
        <Dialog
            className={classes.modal}
            open={open}
            // onClose={handleClose}
            aria-labelledby="form-dialog-title"
            BackdropProps={{
                style: {
                    backgroundColor: 'rgba(126, 121, 62, 0.38)'
                }
            }}
        >
            <img alt="" src={formIcon} className={classes.img} />
            {!showForm ? <>
                <DialogTitle>
                    <Typography variant="h5" align="center">Willkommen zum Hormone Test</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText align="center">
                        Der folgende Selbsttest kann dir einen guten Einblick vermitteln, ob und in welchen Bereichen dein Hormonsystem Störungen aufweist und inwiefern dadurch deine Gene epigenetisch beeinträchtigt werden können (ja oder nein-Fragen sind mit immer/nie zu beantworten).
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button style={{ width: '200px', marginBottom: '15px' }} size="large" onClick={() => setShowForm(true)} color="secondary" variant="contained">
                        Weiter
                    </Button>
                </DialogActions>
            </> : <>
                <DialogTitle>
                    <Typography variant="h5" align="center">Deine Daten</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText align="center">
                        Damit du am Ende eine Auswertung per Mail zugesendet bekommst, brauchen wir die Folgenden Daten bevor wir loslegen können.
                    </DialogContentText>
                    <div className={classes.inputsWrapper}>
                        <TextField
                            variant="outlined"
                            color="primary"
                            margin="dense"
                            label="Vorname*"
                            fullWidth
                            id="firstName"
                            name="firstName"
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            helperText={formik.touched.firstName && formik.errors.firstName}
                        />
                        <TextField
                            variant="outlined"
                            color="primary"
                            margin="dense"
                            label="Nachname*"
                            fullWidth
                            id="lastName"
                            name="lastName"
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                            helperText={formik.touched.lastName && formik.errors.lastName}
                        />
                    </div>
                    <div className={classes.inputsWrapper}>
                        <TextField
                            variant="outlined"
                            color="primary"
                            margin="dense"
                            label="Email*"
                            type="email"
                            fullWidth
                            id="email"
                            name="email"
                            autoComplete="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <TextField
                            variant="outlined"
                            color="primary"
                            margin="dense"
                            label="Tel"
                            type="tel"
                            fullWidth
                            id="tel"
                            name="tel"
                            value={formik.values.tel}
                            onChange={formik.handleChange}
                            error={formik.touched.tel && Boolean(formik.errors.tel)}
                            helperText={formik.touched.tel && formik.errors.tel}
                        />
                    </div>
                    <div className={classes.inputsWrapper}>
                        <FormControl fullWidth variant="outlined" margin="dense" className={classes.formControl}>
                            <InputLabel id="sex-label">Geschlecht</InputLabel>
                            <Select
                                labelId="sex-label"
                                id="sex-select"
                                label="Geschlecht"
                                value={sex}
                                onChange={e => setSex(e.target.value)}
                            >
                                <MenuItem value={'m'}>Männer</MenuItem>
                                <MenuItem value={'w'}>Frauen</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            variant="outlined"
                            color="primary"
                            margin="dense"
                            label="Alter"
                            type="number"
                            fullWidth
                            value={age}
                            onChange={e => setAge(e.target.value)}
                        />
                    </div>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Button style={{ width: '200px', marginBottom: '15px' }} size="large" onClick={formik.submitForm} color="secondary" variant="contained">
                        Starten
                    </Button>
                </DialogActions>
            </>}
        </Dialog>
    );
}

export default StartForm