import React from "react"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom"
import QuizScreen from "./screens/QuizScreen"
import QuizListScreen from './screens/QuizListScreen'

const Routes = () => (
    <Router>
        <Switch>
            <Route path="/" exact render={() => <Redirect to="/test/darm" />} />
            <Route path="/test/:id" component={QuizScreen} />
            <Route path="/hormone" component={QuizListScreen} />
        </Switch>
    </Router>
)

export default Routes