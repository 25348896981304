const sleep = {
  1: {
    title: "SUBJEKTIVE SCHLAFQUALITÄT",
    questions: [
      {
        question:
          "Wie würdest du insgesamt die Qualität deines Schlafs während der letzten vier Wochen beurteilen?",
        answers: ["Sehr gut", "Ziemlich gut", "Ziemlich schlecht", "Schlecht"],
      },
    ],
  },
  2: {
    title: "SCHLAFLATENZ",
    questions: [
      {
        question:
          "Wie lange hat es während der letzten vier Wochen für gewöhnlich gedauert, bis du Nachts eingeschlafen bist?",
        answers: ["15-20 min", "20-30 min", "0-15 min", "> 30 min"],
      },
    ],
  },
  3: {
    title: "SCHLAFDAUER",
    questions: [
      {
        question:
          "Wie viele Stunden hast du während der letzten vier Wochen pro Nacht tatsächlich geschlafen? (Stimmt nicht mit der Anzahl der Stunden überein, die du im Bett verbracht hast)",
        answers: [">7 Std.", "6-7 Std.", "5-6 Std.", "<5 Std."],
      },
    ],
  },
  4: {
    title: "SCHLAFEFFIZIENZ",
    questions: [
      {
        question:
          "Anzahl der Stunden, die du geschaffen hast ÷ durch die Anzahl der Stunden, die du im Bett verbracht hast x 100 (verwende dabei eine durchschnittliche Nacht)",
        answers: ["85%", "75-84%", "65-74%", "< 65%"],
      },
    ],
  },
  5: {
    title: "SCHLAFEFFIZIENZSTÖRUNGEN",
    questions: [
      {
        question:
          "Wie oft hast du innerhalb der letzten vier Wochen schlecht geschlafen, weil du mitten in der Nacht oder früh morgens aufgewacht bist?",
        answers: ["gar nicht", "< 1/Woche", "1-2/Woche", "≥3/Woche"],
      },
      {
        question:
          "Wie oft hast du innerhalb der letzten vier Wochen schlecht geschlafen, weil du aufstehen musstest, um zur Toilette zu gehen?",
        answers: ["gar nicht", "< 1/Woche", "1-2/Woche", "≥3/Woche"],
      },
      {
        question:
          "Wie oft hast du innerhalb der letzten vier Wochen schlecht geschlafen, weil du Beschwerden beim Atmen hattest?",
        answers: ["gar nicht", "< 1/Woche", "1-2/Woche", "≥3/Woche"],
      },
      {
        question:
          "Wie oft hast du innerhalb der letzten vier Wochen schlecht geschlafen, weil du husten musstest oder laut geschnarcht hast?",
        answers: ["gar nicht", "< 1/Woche", "1-2/Woche", "≥3/Woche"],
      },
      {
        question:
          "Wie oft hast du innerhalb der letzten vier Wochen schlecht geschlafen, weil dir zu kalt war?",
        answers: ["gar nicht", "< 1/Woche", "1-2/Woche", "≥3/Woche"],
      },
      {
        question:
          "Wie oft hast du innerhalb der letzten vier Wochen schlecht geschlafen, weil dir zu warm war?",
        answers: ["gar nicht", "< 1/Woche", "1-2/Woche", "≥3/Woche"],
      },
      {
        question:
          "Wie oft hast du innerhalb der letzten vier Wochen schlecht geschlafen, weil du schlecht geträumt hast?",
        answers: ["gar nicht", "< 1/Woche", "1-2/Woche", "≥3/Woche"],
      },
      {
        question:
          "Wie oft hast du innerhalb der letzten vier Wochen schlecht geschlafen, weil du Schmerzen hattest?",
        answers: ["gar nicht", "< 1/Woche", "1-2/Woche", "≥3/Woche"],
      },
      {
        question:
          "Wie oft hast du innerhalb der letzten vier Wochen schlecht geschlafen, aus anderen Gründen?",
        answers: ["gar nicht", "< 1/Woche", "1-2/Woche", "≥3/Woche"],
      },
    ],
  },
  6: {
    title: "SCHLAFMITTELKONSUM",
    questions: [
      {
        question:
          "Wie oft hast du während der letzten vier Wochen Schlafmittel eingenommen (vom Arzt verschieben oder frei verkäufliche)?",
        answers: ["gar nicht", "< 1/Woche", "1-2/Woche", "≥3/Woche"],
      },
    ],
  },
  7: {
    title: "TAGESMÜDIGKEIT",
    questions: [
      {
        question:
          "Wie oft hattest du während der letzten vier Wochen Schwierigkeiten wach zu bleiben, etwa beim Autofahren, beim Essen oder bei gesellschaftlichen Anlässen?",
        answers: ["gar nicht", "< 1/Woche", "1-2/Woche", "≥3/Woche"],
      },
      {
        question:
          "Hattest du während der letzte vier Wochen Probleme mit genügend Schwung die üblichen Alltagsaufgaben zu erledigen?",
        answers: ["keine", "kaum", "etwas", "große"],
      },
    ],
  },
};

export default sleep;
