import CssBaseline from "@material-ui/core/CssBaseline";
import React, { useEffect, useMemo, useState } from "react";
import { hormoneMen, hormoneWomen } from "../../data/hormon";
import getResult from "../../helpers/getHormoneResult";
import ShopModal from "../QuizScreen/components/ShopModal";
import Header from "./components/Header";
import Nav from "./components/Nav";
import Result from "./components/Result";
import StartForm from "./components/StartForm";
import TestActions from "./components/TestActions";
import TestQuestion from "./components/TestQuestion";

const QuizListScreen = () => {
  const [sex, setSex] = useState("m");
  const [age, setAge] = useState(20);
  const test = sex === "m" ? hormoneMen : hormoneWomen;
  const [activeAnswers, setActiveAnswers] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [showShopModal, setShowShopModal] = useState(false);
  const [result, setResult] = useState(false);
  const [activeTeil, setActiveTail] = useState(0);
  const [answers, setAnswers] = useState({});
  const [userData, setUserData] = useState({})
  const teils = useMemo(() => {
    return Object.keys(test);
  }, [test]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeTeil, result]);

  const handleNext = () => {
    if (!!test[teils[activeTeil + 1]]) {
      setActiveTail(activeTeil + 1);
      setActiveAnswers(answers[teils[activeTeil + 1]] || []);
    } else {
      let newAnswers = { ...answers, [teils[activeTeil]]: activeAnswers };
      setResult(getResult(sex, age, newAnswers));
    }
    setAnswers({ ...answers, [teils[activeTeil]]: activeAnswers });
  };

  const handleBack = () => {
    if (!!test[teils[activeTeil - 1]]) {
      setActiveTail(activeTeil - 1);
      setActiveAnswers(answers[teils[activeTeil - 1]]);
    }
  };

  const onCheckboxClick = (i) => {
    if (activeAnswers.includes(i)) {
      setActiveAnswers(activeAnswers.filter((n) => n !== i));
    } else {
      setActiveAnswers([...activeAnswers, i]);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#fff",
        minHeight: "100vh",
        flexDirection: "column",
        filter: showModal ? "blur(3px)" : null,
      }}
    >
      <CssBaseline />
      <Header />
      <Nav teils={teils} activeTeil={activeTeil} showResult={Boolean(result)} />
      {!result ? (
        <>
          <TestQuestion
            teil={teils[activeTeil]}
            title={test[teils[activeTeil]]?.title}
            question={test[teils[activeTeil]]?.question}
          />
          <TestActions
            answers={test[teils[activeTeil]]?.answers}
            handleNext={handleNext}
            handleBack={handleBack}
            activeAnswers={activeAnswers}
            setActiveAnswer={setActiveAnswers}
            onCheckboxClick={onCheckboxClick}
            isNext={true}
          />
        </>
      ) : (
        <Result result={result} setShowShopModal={setShowShopModal} userData={userData} />
      )}
      <StartForm
        open={showModal}
        sex={sex}
        setSex={setSex}
        age={age}
        setAge={setAge}
        handleClose={() => setShowModal(false)}
        onSubmit={setUserData}
      />
      <ShopModal
        open={showShopModal}
        handleClose={() => setShowShopModal(false)}
        testType="hormone"
        result={result}
      />
    </div>
  );
};

export default QuizListScreen;
