import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    circle: {
        width: '50px',
        height: '50px',
        border: '3px solid rgba(0, 0, 0, 0.1)',
        background: '#FBFBFB',
        borderRadius: '30px',
        marginTop: '10px',
        cursor: 'pointer'
    },
    active: {
        width: '44px',
        height: '44px',
        border: '11px solid #A71580',
        borderRadius: '30px'
    }
}));

const Checkbox = ({ active, label, onClick }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Typography variant="h5" color={active ? "textPrimary" : "textSecondary"}>{label}</Typography>
            <div className={classes.circle} onClick={onClick}>
                {active ? <div className={classes.active} /> : null}
            </div>
        </div>
    )
}

export default Checkbox