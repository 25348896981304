import React, { useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import Header from './components/Header'
import Nav from './components/Nav'
import TestQuestion from './components/TestQuestion'
import TestActions from './components/TestActions'
import StartForm from './components/StartForm'
import ShopModal from './components/ShopModal'
import Result from './components/Result'
import darm from '../../data/darm'
import sleep from '../../data/sleep'
import detox from '../../data/detox'
import getResult from '../../helpers/getResult'

const getTest = testType => {
    switch (testType) {
        case 'darm':
            return darm
        case 'sleep':
            return sleep
        case 'detox':
            return detox
        default:
            return darm
    }
}

const QuizScreen = () => {
    const history = useHistory()
    const { pathname } = history.location
    const testType = pathname.split('/')[2]
    const test = getTest(testType)
    const [activeAnswer, setActiveAnswer] = useState(null)
    const [showModal, setShowModal] = useState(true)
    const [showShopModal, setShowShopModal] = useState(false)
    const [result, setResult] = useState(false)
    const [activeTeil, setActiveTail] = useState(0)
    const [activeQuestion, setActiveQuestion] = useState(0)
    const teils = useMemo(() => {
        return Object.keys(test)
    }, [test])
    const [answers, setAnswers] = useState(new Array(teils.length).fill(1).map(() => []))
    const [userData, setUserData] = useState({})

    const saveAnswer = () => {
        const newAnswers = answers
        newAnswers[activeTeil][activeQuestion] = activeAnswer
        setAnswers(newAnswers)
    }

    const handleNext = () => {
        saveAnswer()
        if (test[teils[activeTeil]]?.questions[activeQuestion + 1]?.question) { // check if next question available in this teil
            setActiveQuestion(activeQuestion + 1)
            setActiveAnswer(answers[activeTeil][activeQuestion + 1])
        } else {
            if (teils[activeTeil + 1]) { // check if next teil available
                setActiveTail(activeTeil + 1)
                setActiveAnswer(answers[activeTeil + 1][0])
            } else {
                setResult(getResult(testType, answers))
            }
            setActiveQuestion(0)
        }
        // setActiveAnswer(0)  // will be removed
    }

    const handleBack = () => {
        if (activeQuestion !== 0) {
            let newActiveQuestion = activeQuestion - 1
            setActiveQuestion(newActiveQuestion)
            setActiveAnswer(answers[activeTeil][newActiveQuestion])
        } else {
            if (activeTeil !== 0) {
                let newActiveTeil = activeTeil - 1
                setActiveTail(newActiveTeil)
                let newActiveQuestion = test[teils[newActiveTeil]]?.questions?.length - 1
                setActiveQuestion(newActiveQuestion)
                setActiveAnswer(answers[newActiveTeil][newActiveQuestion])
            }
        }
    }

    return (
        <div style={{ display: 'flex', backgroundColor: '#fff', minHeight: '100vh', flexDirection: 'column', filter: showModal ? 'blur(3px)' : null }}>
            <CssBaseline />
            <Header />
            <Nav teils={teils} activeTeil={activeTeil} showResult={Boolean(result)} />
            {!result ? <>
                <TestQuestion
                    testType={testType}
                    teil={teils[activeTeil]}
                    title={test[teils[activeTeil]]?.title}
                    question={test[teils[activeTeil]]?.questions[activeQuestion]?.question}
                />
                <TestActions
                    answers={test[teils[activeTeil]]?.questions[activeQuestion]?.answers}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    activeAnswer={activeAnswer}
                    setActiveAnswer={setActiveAnswer}
                    isNext={typeof activeAnswer === 'number'}
                />
            </> : 
                <Result
                    result={result}
                    testType={testType}
                    setShowShopModal={setShowShopModal}
                    userData={userData}
                />
            }
            <StartForm open={showModal} handleClose={() => setShowModal(false)} testType={testType} onSubmit={setUserData} />
            <ShopModal open={showShopModal} handleClose={() => setShowShopModal(false)} testType={testType} result={result} />
        </div>
    )
}

export default QuizScreen