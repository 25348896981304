const darm = {
    'Teil 1': {
        title: 'Hypoazidität',
        questions: [
            {
                question: 'Musst du aufstoßen? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Hast du ein verlängertes Völlegefühl nach Mahlzeiten? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Fühlst du dich aufgebläht? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Hast du wenig Appetit? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Bist du anfällig für Magenprobleme? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Hast du Verstopfung? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Hast du bekannte Lebensmittelunverträglichkeiten? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Hast du Eisenmangel? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Verspürst du Übelkeit nach der Einnahme von Supplements? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
        ]
    },
    'Teil 2': {
        title: 'Leaky Gut und Dysbiose',
        questions: [
            {
                question: 'Hast du Verstopfung und/oder Durchfall? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Hast du Bauchschmerzen oder fühlst du dich aufgebläht? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Hast du Schleim oder Blut im Stuhl? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Hast du Gelenkschmerzen, Schwellungen oder Arthritis? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Leidest du an chronischer Müdigkeit? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Hast du eine verstopfte Nase oder verstopfte Nebenhöhlen? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Hast du Ekzeme, Hautausschlag oder Nesseln? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Hast du Asthma oder Hauschnupfen? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Leidest du an Verwirrtheit, einem schwachen Gedächtnis oder Stimmungsschwankungen? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Verwendest du Schmerzmittel (Aspirin, Paracetamol ... )? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Hast du in der Vergangenheit Antibiotika eingenommen? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Konsumierst du Alkohol? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Leidest du an Colitis, Morbus Crohn oder Zöliakie? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Leidest du an Kopfschmerzen? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
        ]
    },
    'Teil 3': {
        title: 'Bakterielle Überbesiedlung des Dünndarms',
        questions: [
            {
                question: 'Hast du Blähungen? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Fühlst du dich aufgebläht (vor allem nach der Einnahme von Zucker, Kohlenhydraten und Ballaststoffen)? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Hast du Durchfall? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Leidest du an Bauchschmerzen? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Hast du einen Reizdarm? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Leidest du an Fibromyalgie (Muskelschmerzen)? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Leidest du am Restless-Legs-Syndrom? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Hast du eine Intoleranz gegenüber Probiotika? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Hast du mehr als 9 Punkte in Teil 1 (Hypoazidität)? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Verwendest du Antacidum oder Protonenpumpenhemmer (Magensäureblocker)? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
        ]
    },
    'Teil 4': {
        title: 'Dickdarm',
        questions: [
            {
                question: 'Leidest du an wiederkehrendem Durchfall? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Leidest du an wiederkehrenden Infektionen (Erkältungen)? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Hast du Blasen- und Niereninfektionen? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Leist du an vaginalen Infekten? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Leidest du an Bauchkrämpfen? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Hast du einen Pilz an Finger- und Zehennägeln? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Hast du Durchfall und Verstopfung? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Hast du in der Vergangenheit Antibiotika eingenommen? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Isst du Fleisch? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Leidest du an wiederkehrenden Bauchschmerzen? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Hast du Blut oder Eiter im Stuhl? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Kommt die chronisch-entzündliche Darmerkrankung in der Familienhistorie vor? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
            {
                question: 'Leidest du an schnellem Nachlassen der Sehstärke? (nie = 0 Punkte, manchmal = 1 Punkt, oft = 2 Punkte und immer = 3 Punkte)',
                answers: ['Nie', 'Manchmal', 'Oft', 'Immer']
            },
        ]
    },
}

export default darm