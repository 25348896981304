import capitalizeFirstLetter from "./capitalizeFirstLetter";

export const sendEmail = (testType, userData, result) => {
  const testTypeCapitalized = capitalizeFirstLetter(testType);

  let emailBody = `<h3>${testTypeCapitalized} Test</h3>

    von<br /><br />
    
    Vorname: <b>${userData.firstName}</b><br />
    Nachname: <b>${userData.lastName}</b><br />
    E-Mail-Adresse: <b>${userData.email}</b>
    ${userData.age ? `<br />Alter: <b>${userData.age}</b><br />` : "<br />"}
    Tel.: <b>${userData.tel}</b><br /><br />
    
    Das Ergebnis:`;

  if (testType === "hormone") {
    // result - array
    result.forEach((item) => {
      emailBody = emailBody.concat(
        "\n",
        item.title ? `<h3>${item.title}</h3><p>${item.text}</p>` : `<p>${item.text}</p>`
      );
    });
  } else {
    // result - object
    emailBody = emailBody.concat(
      "\n",
      result.title ? `<h3>${result.title}</h3><p>${result.text}</p>` : `<p>${result.text}</p>`
    );
  }

  window.Email.send({
    Host: "mail.movchin.de",
    Username: "quiz@epi-genes.com",
    Password: "@dkMzysccQKUCb4Wix3V",
    To: `${userData.email}, support@epi-genes.com, gruber@natugena.de`,
    From: "quiz@epi-genes.com",
    Subject: `Neue EPI-GENES Quiz Resultate`,
    Body: emailBody,
  });
};
